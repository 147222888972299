import { Layout, Image } from 'antd';
import './App.css';
const { Header, Footer, Content } = Layout;

function App() {
  return (
    <Layout className='layout'>
      <Header className='header'>
        柏思克
        {/* <div className='logo'>上海奕肯信息技术有限公司</div> */}

      </Header>
      <Content className='content'>
        <div className='title'>
          <div className='title-content'>
            <img  className='banner' src={require('./banner.jpeg')} />
            <div className='title-text'> 欢迎来到 柏思克</div>
            <div className='title-line'> 在这里，你可以搭建属于你自己的网站，快来试试吧</div>
           
           

            {/* <h1 className='title-h1'>上海奕肯信息技术有限公司</h1> */}
          </div>
        </div>
        <div className='home'>
          <div className='div'>
            <h3>网站开发</h3>
            <p>全面定制化网络服务，满足您的个性化需求, 让您的网站更加独一无二!</p>
          </div>
          <div className='div'>
            <h3>app 开发</h3>
            <p>针对各行业，移动终端自适应的应用开发，让您的应用更加流畅、高效!</p>
          </div>
          <div className='div'>
            <h3>对接微信，支付宝等第三方支付系统</h3>
            <p>充分了解市场需求，提供专业的对接服务，让您的业务更加顺畅!</p>
          </div>
          <div className='div'>
            <h3>会议会展服务</h3>
            <p>根据客户需求,提供专业的会议会展服务，让您的活动更加圆满!</p>
          </div>
        </div>
      </Content>
      <Footer className='footer'>
        <div className='footerdiv'>© 法律声明 © 隐私保护 ©2024 上海柏思克信息技术有限公司 版权所有 | 地址：上海市松江区王家厍路199弄20号1502室 联系电话：18516286235 | <a href="https://beian.miit.gov.cn/#/Integrated/index" target="__blank">沪ICP备2024101130号-1</a>    | <img style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '10px' }} src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png"></img><a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011702890075" rel="noreferrer" target="_blank">沪公网安备31011702890075号</a></div>
        
        {/* <p>© 法律声明 © 隐私保护 ©2019 上海奕肯信息技术有限公司 版权所有 <a href="https://beian.miit.gov.cn/#/Integrated/index" target="__blank">沪ICP备19019249号-1</a>
          <img style={{ width: '20px', height: '20px', marginLeft: '20px', marginRight: '10px' }} src="https://beian.mps.gov.cn/img/logo01.dd7ff50e.png"></img><a href="https://beian.mps.gov.cn/#/query/webSearch?code=31011802005020" rel="noreferrer" target="_blank">沪公网安备31011802005020</a></p>
        <p style={{ fontSize: '15px', color: '#999' }}>办公地址：上海市青浦区赵巷镇崧泉路1251号三楼101室 联系电话：021-59831500</p> */}
      </Footer>
    </Layout>
  );
}

export default App;
